import { Link } from '@root/components/ui/links/link/';
import Image from 'next/image';

import { Headers } from '@root/components/app/headers';
import EmbargoImg from '@root/images/logos/embargo.svg';
import img404 from '@root/static/pages/logging.jpeg';

export default function Page404() {
	return (
		<>
			<Headers
				title='404 Page'
				desc='Resource not found. Sorry, we couldn’t find the page you’re looking for.'
				statusCode={400}
			/>
			<div className='flex min-h-full flex-col bg-white lg:relative min-h-screen'>
				<div className='flex flex-grow flex-col'>
					<main className='flex flex-grow flex-col bg-white'>
						<div className='mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8'>
							<div className='flex-shrink-0 pt-10 sm:pt-16'>
								<Link
									href='/'
									className='inline-flex'>
									<span className='sr-only'>Embargo</span>
									<Image
										className='h-12 w-auto'
										src={EmbargoImg}
										alt='Embargo logo'
										width={56}
									/>
								</Link>
							</div>
							<div className='my-auto flex-shrink-0 py-16 sm:py-32'>
								<p className='text-base font-semibold text-indigo-600'>
									404
								</p>
								<h1 className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
									Resource not found
								</h1>
								<p className='mt-2 text-base text-gray-500'>
									Sorry, we couldn’t find the page you’re
									looking for.
								</p>
								<div className='mt-6'>
									<Link
										href='/'
										className='text-base font-medium text-indigo-600 hover:text-indigo-500'>
										Go back home
										<span aria-hidden='true'> &rarr;</span>
									</Link>
								</div>
							</div>
						</div>
					</main>
					<footer className='flex-shrink-0 bg-gray-50'>
						<div className='mx-auto w-full max-w-7xl px-4 py-16 sm:px-6 lg:px-8'>
							<nav className='flex space-x-4'>
								{/* <Link href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                    Contact Support
                  </Link>
                  <span className="inline-block border-l border-gray-300" aria-hidden="true" />
                  <Link href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                    Status
                  </Link>
                  <span className="inline-block border-l border-gray-300" aria-hidden="true" />
                  <Link href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                    Twitter
                  </Link> */}
							</nav>
						</div>
					</footer>
				</div>
				<div className='hidden lg:absolute lg:inset-y-0 lg:right-0 lg:block lg:w-1/2'>
					<Image
						className='absolute inset-0 h-full w-full object-cover'
						src={img404}
						alt='Deforestation - Resource not found'
						fill
						sizes='(max-width: 1020px) 0vw, 50vw'
					/>
				</div>
			</div>
		</>
	);
}
